import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { addLocaleData } from "react-intl";
import nl from "react-intl/locale-data/nl";
import "core-js/stable";
import LogRocket from "logrocket";

import App from "./App";
import store from "./store/store";

// eslint-disable-next-line no-undef
if ((process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "production") && LogRocket?.init) {
	try {
		LogRocket.init("fkv4x9/bothive-app", {
			mergeIframes: true,
		});
	} catch (error) {
		console.error("Failed to load log rocket", error);
	}
}

addLocaleData(nl);

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root"));
