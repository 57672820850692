import { combineReducers } from "redux";

import {
	reducers as flowReducers,
	actions as flowActions,
} from "./flow";

import {
	reducers as organizationReducers,
	actions as organizationActions,
} from "./organization";

import {
	reducers as reportReducers,
	actions as reportActions,
} from "./report";

export const actions = {
	flow: flowActions,
	organization: organizationActions,
	report: reportActions,
};

export const reducers = combineReducers({
	flow: flowReducers,
	organization: organizationReducers,
	report: reportReducers,
});
