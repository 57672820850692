import axios from "axios";

import actionTypes from "./report.actionTypes";
import { apiConfig } from "../../../config";

export const isFetching = () => dispatch => {
	dispatch({
		type: actionTypes.REPORT_IS_FETCHING,
	});
};
export const fetchingFailed = () => dispatch => {
	dispatch({
		type: actionTypes.FETCHING_REPORT_FAILED,
	});
};
export const fetchingSucceeded = () => dispatch => {
	dispatch({
		type: actionTypes.FETCHING_REPORT_SUCCEEDED,
	});
};

export const resetReport = () => dispatch => {
	dispatch({ type: actionTypes.RESET_REPORTS });
};

export const fetchReportById = ({ reportId }) => async dispatch => {
	dispatch(isFetching());

	try {
		const url = apiConfig.flow.getReportById.replace(":reportId", reportId);

		const result = await axios.get(url);

		if (result.status !== 200 || !result.data.success) {
			throw new Error();
		}

		dispatch({
			type: actionTypes.FETCH_REPORT_BY_ID,
			data: result.data.data,
		});
		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};
