import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

import { pageConfig, routesConfig } from "../config";
import { Link } from "../components/atoms";
import { Fallback } from "../components/molecules";

const mapStateToProps = state => ({
	slug: state.dashboard.header.slug,
	flowId: state.dashboard.header.flowId,
	organization: state.server.organization.organization,
});

function ExpiredPage({ slug, flowId, organization, history }) {
	const url = routesConfig.root.replace(":organizationSlug", slug).replace(":flowId", flowId);

	return (
		<div className="t-layout--central">
			<Fallback.MExpired {...pageConfig.expired} >
				{organization?.team && <p>
					<FormattedMessage id={pageConfig.expired.link} />
					<Link.ASaveEmail url={organization.team.email}>{organization?.team?.email}</Link.ASaveEmail>
				</p>}
				{slug && flowId && <Link.AInternal
					url={`${url}${history.location.search}`}
					className="a-button--normal t-gap--top"
				>
					<FormattedMessage id={pageConfig.expired.tryAgain} />
				</Link.AInternal>}
			</Fallback.MExpired>
		</div>
	);
}

export default withRouter(connect(mapStateToProps, {
})(injectIntl(ExpiredPage)));
