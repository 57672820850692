import { combineReducers } from "redux";

import {
	reducers as bannerReducers,
	actions as bannerActions,
} from "./banner";

import {
	reducers as errorHandlingReducers,
	actions as errorHandlingActions,
} from "./errorHandling";

import {
	reducers as headerReducers,
	actions as headerActions,
} from "./header";

export const reducers = combineReducers({
	banner: bannerReducers,
	errorHandling: errorHandlingReducers,
	header: headerReducers,
});

export const actions = {
	banner: bannerActions,
	errorHandling: errorHandlingActions,
	header: headerActions,
};
