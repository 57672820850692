import { icon } from "../assets/images";

export default {
	language: {
		key: "nl",
	},
	expired: {
		img: icon.brokenLink,
		title: "expired.fallback.title",
		description: "expired.fallback.description",
		link: "expired.fallback.link",
		tryAgain: "expired.fallback.button.try_again",
	},
	landbot: {
		url: {
			v3: "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js",
		},
		deprecated: {
			title: "warning.deprecation.browser.title",
			description: "warning.deprecation.browser.description",
			items: [
				{
					id: "chrome",
					src: icon.chrome,
					url: "https://www.google.com/chrome/",
					title: "warning.deprecation.browser.chrome.title",
					description: "warning.deprecation.browser.chrome.description",
				},
				{
					id: "edge",
					src: icon.edge,
					url: "https://www.microsoft.com/en-us/edge",
					title: "warning.deprecation.browser.edge.title",
					description: "warning.deprecation.browser.edge.description",
				},
				{
					id: "firefox",
					src: icon.firefox,
					url: "https://www.mozilla.org/en-US/firefox/new/",
					title: "warning.deprecation.browser.firefox.title",
					description: "warning.deprecation.browser.firefox.description",
				},
				{
					id: "safari",
					src: icon.safari,
					url: "https://support.apple.com/en-us/HT204416",
					title: "warning.deprecation.browser.safari.title",
					description: "warning.deprecation.browser.safari.description",
				},
			],
		},
	},
};
