export default {
	widget: {
		defaultValue: "circle",
	},
	mobileWidth: 560,
	color: {
		brandPrimaryFontColor: {
			primary: "#FFFFFF",
			fallback: "#000000",
		},
		accentColor: {
			primary: "#FFFFFF",
			fallback: "#000000",
		},
		botFontColor: {
			primary: "#000000",
			fallback: "#FFFFFF",
		},
		minimumContrastRation: 2,
	},
};
