import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import qs from "qs";

import { routesConfig } from "../../config";
import { Header } from "../../components/organisms";
import { Layout } from "../../components/templates";
import { actions as dashboardActions } from "../../store/dashboard";
import { actions as serverActions } from "../../store/server";

const mapStateToProps = (state) => ({
	report: state.server.report.report,
	reportIsFetching: state.server.report.isFetching,
	reportFetchingSucceeded: state.server.report.fetchingSucceeded,
	reportFetchingFailed: state.server.report.fetchingFailed,
	flow: state.server.flow.flow,
	organization: state.server.organization.organization,
	flowIsFetching: state.server.flow.isFetching,
	fetchingFlowSucceeded: state.server.flow.fetchingSucceeded,
	fetchingFlowFailed: state.server.flow.fetchingFailed,
	organizationIsFetching: state.server.organization.isFetching,
});

class TemplatePage extends React.Component {
	componentDidMount() {
		try {
			this.props.initRequest({
				flowId: this.props.match.params.flowId,
				slug: this.props.match.params.organizationSlug,
			});
			this.props.fetchOrganization(
				this.props.match.params.organizationSlug
			);
			this.props.fetchFlowById(this.props.match.params.flowId);

			const queryParams = qs.parse(this.props.location.search, {
				ignoreQueryPrefix: true,
			});

			if (queryParams?.reportId) {
				this.props.fetchReportById({ reportId: queryParams?.reportId });
			}
		} catch (error) {
			const expiredUrl = `${routesConfig.expired}${this.props.history.location.search}`;

			this.props.history.push(expiredUrl);
			console.error("Mount template failed", error);
		}
	}

	componentDidUpdate() {
		const expiredUrl = `${routesConfig.expired}${this.props.history.location.search}`;

		if (
			this.props.fetchingFlowFailed ||
			(this.props.fetchingFlowSucceeded &&
				this.props.flow &&
				!this.props.flow.isEnabled)
		) {
			this.props.history.push(expiredUrl);
		}

		if (this.props.reportFetchingFailed) {
			this.props.history.push(expiredUrl);
		}
	}

	render() {
		if (
			this.props.organizationIsFetching ||
			this.props.flowIsFetching ||
			this.props.reportIsFetching ||
			!this.props.flow
		) {
			return <Layout.TLoadScreen />;
		}

		return (
			<div className="t-layout--flow-template">
				<Header.ODefault
					logo={this.props.organization?.branding?.icon}
					title={this.props.organization?.name}
					description={
						this.props.flow?.widgetSettings?.name ||
						this.props.flow?.template?.name?.nl
					}
				/>
				{this.props.children}
				<div></div>
			</div>
		);
	}
}

TemplatePage.propTypes = {
	initRequest: PropTypes.func.isRequired,
	fetchOrganization: PropTypes.func.isRequired,
	fetchFlowById: PropTypes.func.isRequired,
};

export default withRouter(
	connect(mapStateToProps, {
		fetchOrganization: serverActions.organization.fetchOrganization,
		initRequest: dashboardActions.header.initRequest,
		fetchFlowById: serverActions.flow.fetchFlowById,
		fetchReportById: serverActions.report.fetchReportById,
	})(injectIntl(TemplatePage))
);
