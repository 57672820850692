import React from "react";
import PropTypes from "prop-types";

import { timingConfig } from "../../../config";
import { keyEvents } from "../../../helpers";

export default function MDefault({ error, success, timing, children, className, onClose }) {
	let state = "";
	const timer = setTimeout(() => handleClose(), timing);
	const handleClose = () => {
		clearTimeout(timer);
		onClose();
	};
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event)) {
			onClose();
		}
	};

	switch (true) {
		case error:
			state = "error";
			break;
		case success:
			state = "success";
			break;
		default:
			break;
	}

	return (
		<section
			role="dialog"
			onClick={handleClose}
			onKeyUp={handleKeyUp}
			data-state={state}
			className={`m-banner ${className}`}
		>
			{children}
		</section>
	);
}

MDefault.propTypes = {
	error: PropTypes.bool,
	success: PropTypes.bool,
	timing: PropTypes.number,
	children: PropTypes.any,
	className: PropTypes.string,
	onClose: PropTypes.func,
};

MDefault.defaultProps = {
	error: false,
	success: false,
	timing: timingConfig.bannerTimeout,
	children: undefined,
	className: "",
	onClose: undefined,
};
