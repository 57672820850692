import axios from "axios";

import actionTypes from "./organization.actionTypes";
import { apiConfig } from "../../../config";
import { actions as headerActions } from "../../dashboard/header";
import store from "../../store";

export const isFetching = () => dispatch => {
	dispatch({ type: actionTypes.ORGANIZATION_IS_FETCHING });
};
export const fetchingFailed = () => dispatch => {
	dispatch({ type: actionTypes.FETCHING_ORGANIZATION_FAILED });
};
export const fetchingSucceeded = () => dispatch => {
	dispatch({ type: actionTypes.FETCHING_ORGANIZATION_SUCCEEDED });
};

export const fetchOrganization = (slug) => async dispatch => {
	dispatch(isFetching());

	const state = store.getState();

	if (state.server.organization.organization?.slug === slug) {
		dispatch({ type: actionTypes.ORGANIZATION_IS_ALREADY_FETCHED });
		return;
	}

	try {
		const result = await axios.get(apiConfig.organization.getBySlug.replace(":slug", slug));

		if (result.status !== 200) {
			console.error("Failed to fetch organization", slug, result);
			throw new Error();
		}

		dispatch({
			type: actionTypes.FETCH_ORGANIZATION_BY_SLUG,
			data: result.data.data,
		});
		dispatch(headerActions.initOrganizationStyle(result.data.data));
		dispatch(fetchingSucceeded());
	} catch (error) {
		console.error("Failed to fetch organization", slug, error);
		dispatch(fetchingFailed());
	}
};
