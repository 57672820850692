import axios from "axios";

import actionTypes from "./flow.actionTypes";
import { apiConfig } from "../../../config";

export const isFetching = () => dispatch => {
	dispatch({
		type: actionTypes.FLOW_IS_FETCHING,
	});
};
export const fetchingFailed = () => dispatch => {
	dispatch({
		type: actionTypes.FETCHING_FLOW_FAILED,
	});
};
export const fetchingSucceeded = () => dispatch => {
	dispatch({
		type: actionTypes.FETCHING_FLOW_SUCCEEDED,
	});
};

export const fetchFlowById = (flowId) => async dispatch => {
	dispatch(isFetching());

	try {
		const result = await axios.get(apiConfig.flow.getFlowTemplate.replace(":flowId", flowId));

		if (result.status !== 200 || !result.data.success) {
			console.error("Failed to fetch flow", flowId, result);
			throw new Error();
		}

		dispatch({
			type: actionTypes.FETCH_FLOW_BY_ID,
			data: result.data.data,
		});
		dispatch(fetchingSucceeded());
	} catch (error) {
		console.error("Failed to fetch flow", flowId, error);
		dispatch(fetchingFailed());
	}
};
