import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { widgetConfig } from "../../../config";

const mapStateToProps = state => ({
	settings: state.server.organization.organization?.settings,
});

class OWidgetPreviewDefault extends Component {
	constructor(props) {
		super(props);
		this.scriptTag = undefined;
		this.timeout = undefined;
		this.initScriptTag();
	}

	initScriptTag() {
		if (!document.getElementById(widgetConfig.widgetScriptTagId)) {
			const head = document.getElementsByTagName("head")[0];

			this.scriptTag = document.createElement("script");
			this.scriptTag.type = "text/javascript";
			this.scriptTag.id = widgetConfig.widgetScriptTagId;
			this.scriptTag.src = widgetConfig.widgetScriptUrl;
			this.scriptTag.onload = () => this.initWidget();
			head.appendChild(this.scriptTag);
		}
	}

	initWidget() {
		if (window?.[widgetConfig.windowName]?.widget && this.props.settings?.apiKey) {
			window[widgetConfig.windowName].widget.init({
				apiKey: this.props.settings?.apiKey,
				data: {},
				hidden: true,
				notifications: false,
				logging: false,
			});
		}

		// validate if widget init is successful and retry if not
		this.timeout = setTimeout(() => {
			if (!window?.[widgetConfig.windowName]?.widget?.initialized) {
				this.initWidget();
			}
		}, widgetConfig.widgetInstanceValidationTimeout);
	}

	componentDidUpdate(prevProps) {
		if (this.props.team !== prevProps.team) {
			if (window?.[widgetConfig.windowName]?.widget?.refresh) {
				window[widgetConfig.windowName].widget.refresh();
			}
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	render() {
		return null;
	}
}

OWidgetPreviewDefault.propTypes = {
	team: PropTypes.object,
};

export default withRouter(connect(mapStateToProps)(React.memo(OWidgetPreviewDefault)));
