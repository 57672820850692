const baseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:8980"; //eslint-disable-line no-undef

const endpoints = {
	flow: "/flow/v1",
};

export default {
	baseUrl,
	endpoints,
	flow: {
		getFlowTemplate: `${baseUrl}${endpoints.flow}/flow/:flowId/template`,
		getReportById: `${baseUrl}${endpoints.flow}/report/:reportId`,
	},
	organization: {
		getBySlug: `${baseUrl}${endpoints.flow}/team/:slug`,
	},

	verifyIdentity: `${baseUrl}${endpoints.flow}/identify/contact/:id`,
	createIdentity: `${baseUrl}${endpoints.flow}/identify/contact`,
};
