/* eslint-disable max-len */
export default {
	"expired.fallback.title": "Deze link kan tijdelijk niet geladen worden",
	"expired.fallback.description": "Het lijkt erop dat de link die je probeert te openen tijdelijk niet werkt. Open de link opnieuw om het nog eens te proberen.",
	"expired.fallback.link": "Voor meer information kan je ons contacteren op: ",
	"expired.fallback.button.try_again": "Link opnieuw openen",

	"warning.deprecation.browser.title": "Jouw browsertype wordt niet ondersteund.",
	"warning.deprecation.browser.description": "Het lijkt er op dat jouw browser onze flows niet ondersteunt. Gebruik één van onderstaande browserversies om alle features van de flows veilig en optimaal te gebruiken.",

	"warning.deprecation.browser.chrome.title": "Chrome",
	"warning.deprecation.browser.chrome.description": "versie 90+",
	"warning.deprecation.browser.edge.title": "Edge",
	"warning.deprecation.browser.edge.description": "versie 91+",
	"warning.deprecation.browser.firefox.title": "Firefox",
	"warning.deprecation.browser.firefox.description": "versie 89+",
	"warning.deprecation.browser.safari.title": "Safari",
	"warning.deprecation.browser.safari.description": "versie 13.1.2+",
};
