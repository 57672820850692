import React from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
		this.previousState = false;
	}

	componentDidCatch(error, info) {
		console.error("error", error);
		console.error("info", info);
		this.setState({ hasError: true });
	}

	render() {
		if (this.previousState) {
			this.previousState = false;
			this.setState({ hasError: false });
		}

		if (this.state.hasError) {
			this.previousState = true;
			return <p>{this.props.title}</p>; //TODO create fallback
		}

		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	title: PropTypes.string,
};

ErrorBoundary.defaultProps = {
	title: "NOT_ALLOWED.ERROR_HANDLER.TITLE",
};

export default ErrorBoundary;
