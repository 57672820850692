import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Image, Link } from "../../atoms";

export default function MBrowserListItem({
	url,
	src,
	title,
	description,
	className,
}) {
	return (<li className={`m-list-item--image ${className}`}>
		<Link.ASaveUrl url={url} className="list-item--content">
			<Image.ADefault src={src} className="list-item--image" />
			{title && <p className="list-item--title"><FormattedMessage id={title} /></p>}
			{description && <p><FormattedMessage id={description} /></p>}
		</Link.ASaveUrl>
	</li>);
}

MBrowserListItem.propTypes = {
	url: PropTypes.string,
	src: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	className: PropTypes.string,
};

MBrowserListItem.defaultProps = {
	url: "",
	src: "",
	title: "",
	description: "",
	className: "",
};
