import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

export default function MExpired({
	title,
	description,
	children,
	img,
	className,
}) {
	return (
		<section className={`m-fallback ${className}`}>
			<img src={img} alt="" className="fallback--image" />
			<article className="fallback--content">
				{title && <h1 className="title fallback--title"><FormattedMessage id={title} /></h1>}
				{description && <p><FormattedMessage id={description} /></p>}
				{children}
			</article>
			<div></div>
		</section>
	);
}

MExpired.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	img: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.any,
};

MExpired.defaultProps = {
	title: undefined,
	description: undefined,
	img: undefined,
	className: "",
	children: null,
};
