export default {
	direction: {
		horizontal: "horizontal",
		vertical: "vertical",
	},
	spacing: {
		right: "right",
		left: "left",
		center: "center",
		spaceBetween: "spaceBetween",
	},
};
