import React from "react";
import { Route, Switch } from "react-router-dom";
import { routesConfig } from "../config";

import { FlowPages } from "../pages";

const Routing = () => (
	<FlowPages.TemplatePage>
		<Switch>
			<Route path={routesConfig.root} component={FlowPages.LandbotPage} />
		</Switch>
	</FlowPages.TemplatePage>
);

export default Routing;
