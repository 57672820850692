import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export default function AInternal({ url, className, children }) {
	return (<NavLink
		to={url}
		className={`a-link--internal ${className}`}
	>
		{children}
	</NavLink>);
}

AInternal.propTypes = {
	url: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.any,
};

AInternal.defaultProps = {
	className: "",
	children: "",
};
