import actionTypes from "./errorHandling.actionTypes";

const initialState = {
	isErrorThrown: false,
	error: undefined,
	info: undefined,
};

export default function (state = initialState, action) {
	const updateState = {
		[actionTypes.ERROR_RESOLVED]: () => ({
			...state,
			isErrorThrown: false,
		}),
		[actionTypes.THROW_NEW_ERROR]: () => ({
			...state,
			...action.data,
			isErrorThrown: true,
		}),
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
