import React from "react";
import PropTypes from "prop-types";

export default function ASaveUrl({ url, className, children  }) {
	return (
		<a
			href={url}
			className={`a-link ${className}`}
			target="_blank"
			rel="noopener noreferrer"
		>
			{children}
		</a>);
}

ASaveUrl.propTypes = {
	url: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.any,
};

ASaveUrl.defaultProps = {
	className: "",
	children: "",
};
