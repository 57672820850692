import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

export default function MExpired({
	title,
	description,
	className,
}) {
	return (
		<section className={`m-header ${className}`}>
			<h1 className="title header--title"><FormattedMessage id={title} /></h1>
			{description && <p><FormattedMessage id={description} /></p>}
		</section>
	);
}

MExpired.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	className: PropTypes.string,
};

MExpired.defaultProps = {
	title: undefined,
	description: undefined,
	className: "",
};
