import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";

import "./store/store";
import translation from "./translation";
import Routes from "./routes";
import { customStyleHelpers } from "./helpers";

import "./assets/style/style.scss";

const mapStateToProps = state => ({
	language: state.dashboard.header.language.slice(0, 2),
	organization: state.server.organization.organization,
});

function App({ language, organization }) {
	return (
		<IntlProvider locale={language} messages={translation[language]}>
			<Fragment>
				<Helmet>
					<style type="text/css">
						{customStyleHelpers.generateSettingsStyle({ organization })}
					</style>
				</Helmet>
				<Routes />
			</Fragment>
		</IntlProvider>
	);
}

export default connect(mapStateToProps, {})(App);
