import actionTypes from "./report.actionTypes";

const initialState = {
	report: undefined,

	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,
};

export default function (state = initialState, action) {
	const updateState = {
		[actionTypes.REPORT_IS_FETCHING]: () => ({
			...state,
			isFetching: true,
			fetchingFailed: false,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCHING_REPORT_FAILED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: true,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCHING_REPORT_SUCCEEDED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: false,
			fetchingSucceeded: true,
		}),
		[actionTypes.FETCH_REPORT_BY_ID]: () => ({
			...state,
			report: action.data,
		}),
		[actionTypes.FETCH_REPORT_FROM_CLIENT]: () => ({
			...state,
			reports: action.data,
		}),
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
