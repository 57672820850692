import React from "react";
import PropTypes from "prop-types";

export default function AImage({ src, className }) {
	return <img src={src} alt="" className={`a-image ${className}`} />;
}

AImage.propTypes = {
	src: PropTypes.string.isRequired,
	className: PropTypes.string,
};

AImage.defaultProps = {
	className: "",
};
