import React from "react";
import PropTypes from "prop-types";

import { Image } from "../../atoms";

export default function ODefault({
	title,
	description,
	logo,
	className,
}) {
	return (<header className={`o-header ${className}`}>
		<div className="header-content">
			<Image.ADefault src={logo} className="header-logo" />
			<div className="header-content-wrapper">
				<h1 className="header-content--title">{title}</h1>
				<p className="header-content--description">{description}</p>
			</div>
		</div>
	</header>);
}

ODefault.propTypes = {
	logo: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	className: PropTypes.string,
};

ODefault.defaultProps = {
	logo: "",
	className: "",
};
