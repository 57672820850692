import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";

import { actions as dashboardActions } from "../store/dashboard";
import { Banner } from "../components/molecules";

const mapStateToProps = state => ({
	banner: state.dashboard.banner,
});

function TemplatePage({
	banner,
	hideBanner,
	children,
}) {
	return (
		<div className="t-layout--wrapper">
			{children}
			{banner.show &&
				<Banner.MDefault {...banner} onClose={hideBanner}>
					<p><FormattedMessage id={banner.message} values={banner.values} /></p>
				</Banner.MDefault>
			}
		</div>
	);
}

TemplatePage.propTypes = {
	hideBanner: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, {
	hideBanner: dashboardActions.banner.hideBanner,
})(injectIntl(TemplatePage)));
