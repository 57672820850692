import React from "react";
import PropTypes from "prop-types";

import { typesConfig } from "../../../config";

export default function MWrapper({
	items,
	direction,
	spacing,
	render,
	className,
}) {
	return (<ul
		data-spacing={spacing}
		data-direction={direction}
		className={`m-list ${className}`}
	>
		{items.map((item) => render(item))}
	</ul>);
}

MWrapper.propTypes = {
	render: PropTypes.func.isRequired,
	items: PropTypes.array,
	direction: PropTypes.string,
	spacing: PropTypes.string,
	className: PropTypes.string,
};

MWrapper.defaultProps = {
	items: [],
	direction: typesConfig.direction.horizontal,
	spacing: typesConfig.spacing.left,
	className: "",
};
