import React from "react";
import PropTypes from "prop-types";

import { Component } from "../../atoms";

export default function TLoadScreen({ className }) {
	return (<div className={`t-layout--loader ${className}`}>
		<Component.ALoader />
	</div>);
}

TLoadScreen.propTypes = {
	className: PropTypes.string,
};

TLoadScreen.defaultProps = {
	className: "",
};
