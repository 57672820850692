import React from "react";
import PropTypes from "prop-types";

import { typesConfig } from "../../../config";
import { Header, List } from "../../molecules";

export default function ODefault({
	title,
	description,
	items,
	className,
}) {
	return (<section className={`o-section ${className}`}>
		<div className="section--content">
			<Header.MDefault
				title={title}
				description={description}
			/>
			<List.MWrapper
				items={items}
				className="t-gap--top"
				direction={typesConfig.direction.horizontal}
				spacing={typesConfig.spacing.center}
				render={item => <List.MBrowserListItem
					id={item.id}
					key={item.id}
					{...item}
				/>}
			/>
		</div>
		<div className="section--footer" />
	</section>);
}

ODefault.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	items: PropTypes.array,
	className: PropTypes.string,
};

ODefault.defaultProps = {
	items: [],
	className: "",
};
