import actionTypes from "./header.actionTypes";

export const initRequest =
	({ flowId, slug }) =>
	(dispatch) => {
		dispatch({
			type: actionTypes.INIT_REQUEST,
			data: { flowId, slug },
		});
	};
export const updatePage = (data) => (dispatch) => {
	dispatch({
		type: actionTypes.UPDATE_PAGE,
		data,
	});
};
export const initOrganizationStyle = () => (dispatch) => {
	dispatch({ type: "INIT_ORGANIZATION_STYLE" });

	// const headChild = document.querySelector("head");
	// const linkTag = document.createElement("link");

	// linkTag.rel = "icon";
	// linkTag.rel = "image/png";
	// linkTag.href = organization?.chatbot?.avatar;

	// headChild.appendChild(linkTag);
};
