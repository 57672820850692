import React from "react";
import PropTypes from "prop-types";
import { Component } from "../../atoms";

export default function OLandbot({ id, isLoading, isHidden, className }) {
	return (
		<div id={id} data-is-loading={isLoading} data-is-hidden={isHidden} className={`o-flow--landbot ${className}`}>
			{isLoading && <Component.ALoader />}
		</div>
	);
}

OLandbot.propTypes = {
	id: PropTypes.string.isRequired,
	isLoading: PropTypes.bool,
};

OLandbot.defaultProps = {
	className: "",
};
