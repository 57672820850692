import React from "react";
import PropTypes from "prop-types";

export default function ASaveEmail({ url, className, children }) {
	return (
		<a
			href={`mailto:${url}`}
			className={`a-link ${className}`}
			target="_blank"
			rel="noopener noreferrer"
		>
			{children}
		</a>);
}

ASaveEmail.propTypes = {
	url: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.any,
};

ASaveEmail.defaultProps = {
	className: "",
	children: "",
};
