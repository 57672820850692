export default {
	getQueryString(history) {
		const queryString = history.location.search;

		if (!queryString.length) {
			return undefined;
		}

		const items = queryString.replace("?", "").split("&");
		const params = {};

		items.forEach(item => {
			const value = item.split("=");

			params[value[0]] = value[1];
		});

		return params;
	},
	addParam({ history, key, value }) {
		const params = this.getQueryString(history) || {};

		params[[key]] = value;

		return JSON.stringify(params).replace(/{/g, "?").replace(/}|"/g, "").replace(/:/g, "=").replace(/,/g, "&");
	},
	createQueryString(obj) {
		return Object.keys(obj).reduce((prev, key) => obj[key] ? [...prev, `${key}=${obj[key]}`] : prev, []).join("&");
	},
};
