import { timeoutHelpers } from "@bothive/helpers";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { pageConfig, storageConfig } from "../../config";
import { authenticationHelpers, queryStringHelpers } from "../../helpers";
import { actions as serverActions } from "../../store/server";
import { Flow, Section } from "../../components/organisms";

const mapStateToProps = (state) => ({
	organization: state.server.organization.organization,
	report: state.server.report.report,
	flow: state.server.flow.flow,
});

class LandbotPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = { showDeprecation: false, isLoading: false };
		this.retryInit = 0;
		this.retryScriptInit = 0;
	}

	async initScriptTag() {
		try {
			const head = document.getElementsByTagName("head")[0];

			this.scriptTag = document.createElement("script");
			this.scriptTag.type = "text/javascript";
			this.scriptTag.SameSite = "None; Secure";
			this.scriptTag.src = pageConfig.landbot.url[this.props.flow.template.meta.version];
			this.scriptTag.onload = () => this.initWidget();
			head.appendChild(this.scriptTag);
		} catch (error) {
			console.error("Init script tag failed", error);
			if (this.retryScriptInit > 10) {
				this.setState({ showDeprecation: true, isLoading: false });
				return;
			}

			this.setState({ isLoading: true });

			this.retryScriptInit = this.retryScriptInit + 1;

			await timeoutHelpers.wait(1000 * this.retryScriptInit);
			this.initScriptTag();
		}
	}

	componentDidMount() {
		this.initScriptTag();
	}

	async getIdentityId() {
		let organizationId = this.props.organization._id;

		try {
			const identityId = localStorage.getItem(storageConfig.keys.uniqueIdentifier);

			return await authenticationHelpers.identifyContact({ organizationId, identityId });
		} catch (error) {
			console.error("Identify contact failed", error);
		}

		// If failed to fetch contact identity with identity id from local storage try again without it
		return await authenticationHelpers.identifyContact({ organizationId });
	}

	async initWidget() {
		let identity;
		const organizationId = this.props.organization?._id;
		const queryParameter = queryStringHelpers.getQueryString(this.props.history);

		if (!queryParameter?.contactId) {
			try {
				identity = await this.getIdentityId(queryParameter);
			} catch (error) {
				console.error("Identify contact without previous identity id failed", error);
			}
		}

		try {
			const customData = {
				team_id: organizationId,
				identity_id: identity?.id,
				flow_id: this.props.flow?._id,
				organization_id: organizationId,
				report_id: queryParameter?.reportId,
				contact_id: queryParameter?.contactId || identity?.contactId,
				env: process.env.REACT_APP_NODE_ENV || "production", //eslint-disable-line no-undef
			};
			const query = queryStringHelpers.createQueryString(customData);

			//eslint-disable-next-line no-undef
			this.landbot = new Landbot.Container({
				index: `${this.props.flow.template.meta.url}?${query}`,
				configUrl: `${this.props.flow.template.meta.url}?${query}`,
				container: "#flowContainerId",
				customData,
			});
		} catch (error) {
			console.error("init widget error", error);
			if (this.retryInit > 10) {
				this.setState({ showDeprecation: true });
				return;
			}

			this.retryInit = this.retryInit + 1;

			await timeoutHelpers.wait(1000 * this.retryInit);

			this.initWidget();
		}
	}

	render() {
		return (
			<div className="t-layout--content">
				{this.state.showDeprecation && <Section.ODeprecated {...pageConfig.landbot.deprecated} />}
				<Flow.OLandbot
					id="flowContainerId"
					isLoading={this.state.isLoading}
					isHidden={this.state.showDeprecation}
				/>
			</div>
		);
	}
}

export default withRouter(
	connect(mapStateToProps, {
		fetchOrganization: serverActions.organization.fetchOrganization,
	})(injectIntl(LandbotPage))
);
