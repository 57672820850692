import cssVars from "css-vars-ponyfill";
import getContrast from "get-contrast";

import { styleConfig } from "../config";

function getFontColor({ backgroundColor, colors, contrast = styleConfig.color.minimumContrastRation }) {
	try {
		return getContrast.ratio(backgroundColor, colors.primary) > contrast ? colors.primary : colors.fallback;
	} catch (error) {
		console.error("Invalid hex color inserted into the ratio function, please check the organization branding");
		return colors.fallback;
	}
}

export default {
	getFontColor,
	generateSettingsStyle({ organization, fontSize, contrast }) {
		const branding = organization?.branding;

		if (!branding) return;

		cssVars({
			variables: {
				fontSize,
				contrast,
				brandPrimaryFontColor: getFontColor({
					backgroundColor: branding.brandColor,
					colors: styleConfig.color.brandPrimaryFontColor,
					contrast,
				}),
				brandColorPrimary: branding.brandColor,
				brandPrimaryLightFontColor: getFontColor({
					backgroundColor: branding.accentColor,
					colors: styleConfig.color.accentColor,
					contrast,
				}),
				brandColorPrimaryLight: branding.accentColor,
				brandColorText: branding.textColor,
			},
		});
	},
	changeFontFamily(fontFamily) {
		cssVars({
			variables: {
				fontFamily,
			},
		});
	},
};
