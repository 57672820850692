import actionTypes from "./banner.actionTypes";

const initialState = {
	show: false,
	success: false,
	error: false,
	message: "",
	values: {},
};

export default function (state = initialState, action) {
	const updateState = {
		// [actionTypes._FAILED]: () => ({
		// 	...state,
		// 	show: true,
		// 	success: false,
		// 	error: true,
		// 	...action.data,
		// }),
		// [actionTypes._SUCCEEDED]: () => ({
		// 	...state,
		// 	show: true,
		// 	success: true,
		// 	error: false,
		// 	...action.data,
		// }),
		[actionTypes.HIDE_BANNER]: () => ({
			...state,
			show: false,
			success: false,
			error: false,
			message: "",
			values: {},
		}),
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
