import React from "react";
import { Redirect, Router, Route, Switch } from "react-router-dom";
import { routesConfig } from "../config";

import ScrollToTop from "./scroll-to-top.route";
import { Handler } from "../components/organisms";
import { ExpiredPage, TemplatePage } from "../pages";
import flowRoutes from "./flow";
import history from "./history";

const Routing = () => (
	<Router history={history}>
		<Handler.OErrorFallback>
			<ScrollToTop>
				<TemplatePage>
					<Switch>
						<Route path={routesConfig.expired} component={ExpiredPage} />
						<Route path={routesConfig.root} component={flowRoutes} />
						<Route render={() => <Redirect to={routesConfig.expired} />} />
					</Switch>
				</TemplatePage>
			</ScrollToTop>
		</Handler.OErrorFallback>
	</Router>
);

export default Routing;
