import actionTypes from "./errorHandling.actionTypes";

export const throwNewError = ({ error, info }) => dispatch => {
	dispatch({
		type: actionTypes.THROW_NEW_ERROR,
		data: {
			error,
			info,
		},
	});
};

export const errorResolved = () => dispatch => {
	dispatch({ type: actionTypes.ERROR_RESOLVED });
};
