import axios from "axios";
// import fingerprint2 from "@fingerprintjs/fingerprintjs";

import { apiConfig, storageConfig } from "../config";

export default {
	identifyContact: async ({ organizationId, identityId }) => {
		if (!identityId) {
			try {
				const query = `?organizationId=${organizationId}`;
				const result = await axios.get(`${apiConfig.verifyIdentity.replace(":id", identityId)}${query}`);

				if (result?.data?.success) {
					return result.data.data;
				}
			} catch (error) {
				console.error("Failed to identity contact", { identityId, organizationId });
			}
		}

		// TEMP disabled fingerprint to improve speed
		// const fingerprint = await getFingerprint();
		const result = await axios.post(apiConfig.createIdentity, { organizationId });

		if (!result || !result.data.success) {
			return undefined;
		}

		localStorage.setItem(storageConfig.keys.uniqueIdentifier, result.data.data.id);

		return result.data.data;
	},
};
