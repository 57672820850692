import React from "react";
import PropTypes from "prop-types";

import { logo } from "../../../assets/images";

export default function ALoader({ className }) {
	return (
		<div className={`a-component--main-loader ${className}`}>
			<div className="icon-loader loader-animation" />

			<img src={logo.logoSmall} alt="" className="loader-logo" />
		</div>
	);
}

ALoader.propTypes = {
	className: PropTypes.string,
};

ALoader.defaultProps = {
	className: "",
};
