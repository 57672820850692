import actionTypes from "./header.actionTypes";
import { pageConfig } from "../../../config";

const initialState = {
	language: pageConfig.language.key,
	slug: undefined,
	flowId: undefined,
	page: {
		title: undefined,
	},
};

export default function (state = initialState, action) {
	const updateState = {
		[actionTypes.INIT_REQUEST]: () => ({
			...state,
			slug: action.data.slug,
			flowId: action.data.flowId,
		}),
		[actionTypes.UPDATE_PAGE]: () => ({
			...state,
			...action.data,
			page: {
				...state.page,
				...action.data,
			},
		}),
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
