// A
// B
// C
// D
// E
// F
// G
// H
// I
// J
// K
// L
import * as TLayout from "./templates.layout";
// M
// N
// O
// P
// Q
// R
// S
// T
// U
// V
// W
// X
// Y
// Z

export const Layout = TLayout;
