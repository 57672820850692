import actionTypes from "./organization.actionTypes";

const initialState = {
	organization: undefined,

	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,
};

export default function (state = initialState, action) {
	const updateState = {
		[actionTypes.ORGANIZATION_IS_FETCHING]: () => ({
			...state,
			isFetching: true,
			fetchingFailed: false,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCHING_ORGANIZATION_FAILED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: true,
			fetchingSucceeded: false,
		}),
		[actionTypes.FETCHING_ORGANIZATION_SUCCEEDED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: false,
			fetchingSucceeded: true,
		}),
		[actionTypes.ORGANIZATION_IS_ALREADY_FETCHED]: () => ({
			...state,
			isFetching: false,
			fetchingFailed: false,
			fetchingSucceeded: true,
		}),
		[actionTypes.FETCH_ORGANIZATION_BY_SLUG]: () => ({
			...state,
			organization: action.data,
		}),
	};

	return updateState[action.type] ? updateState[action.type]() : state;
}
